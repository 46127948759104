<template>
    <div class="scheduler-outer-container">
        <section id="pdf-content" class="scheduler-inner-container">
            <scheduler-header
                :preselected-options="preselectedOptions"
                @onNewRequestFormClick="onNewRequestFormClick"
                @exportToPDF="exportToPDF"
                @onAddAutoAssignShifts="onAddAutoAssignShifts"
            ></scheduler-header>
            <department-scheduler
                ref="DepartmentScheduler"
                v-show="scheduleViewType === 'department'"
            ></department-scheduler>
            <staff-group-scheduler
                v-if="this.$authIsOwner || this.$authIsAdministrator"
                ref="StaffGroupScheduler"
                v-show="scheduleViewType === 'staff_group'"
            ></staff-group-scheduler>
            <staff-scheduler
                ref="StaffScheduler"
                v-show="scheduleViewType === 'staff'"
            ></staff-scheduler>
        </section>
        <request-form-modal
            v-if="showRequestFormModal"
            v-model="showRequestFormModal"
            :location="globalLocation"
            :timezone="globalTimezone"
            :edit-mode="editRequestForm"
            :shift-type="shiftType"
            :initial-request-form="preselectedRequestForm"
            :request-form-ids-by-day="requestFormIdsByDay"
            :auth-staffing-details="authStaffingDetails"
            @onClose="onRequestFormModalClose"
            @onNext="onCreateRequestForm"
            @openUnavailableUsersModal="openUnavailableUsersModal"
        ></request-form-modal>
        <edit-shift-modal
            v-if="showEditShiftModal"
            v-model="showEditShiftModal"
            :request-form="automatedRequestForm"
            :edit-all="editAllAutomatedShifts"
            @onUpdate="onReloadEvents"
        />
        <introductory-modal
            v-if="showIntroductoryModal"
            v-model="showIntroductoryModal"
            @onContinue="onContinueFromIntroductoryModal"
        />
        <add-automated-shift-modal
            v-if="showAddAutomatedShiftModal"
            v-model="showAddAutomatedShiftModal"
            @onNext="onAddAutomatedShiftNext"
        />
        <add-job-titles-modal
            v-if="staffingAutomationModalValue"
            v-model="staffingAutomationModalValue"
            :staffing-automation-id="staffingAutomationModalId"
            :start-date="staffingAutomationModalStartDate"
            :run-date="staffingAutomationModalRunDate"
        />
        <historical-request-form-modal
            v-if="showHistoricalRequestFormModal"
            v-model="showHistoricalRequestFormModal"
            :location="globalLocation"
            :timezone="globalTimezone"
            :initial-request-form="preselectedRequestForm"
            :request-form-ids-by-day="requestFormIdsByDay"
            :auth-staffing-details="authStaffingDetails"
            @onClose="onHistoricalRequestFormModalClose"
            @onNext="onCreateHistoricalRequestForm">
        </historical-request-form-modal>
        <AssignedEventModal
            :show-modal="showAssignedEventModal"
            :event-prop="assignedModalEvent"
            :current-location="globalLocation"
            :auth-staffing-details="authStaffingDetails"
            @onUnassignShift="onReloadEvents"
            @onDeclineShiftCancelationRequest="onReloadEvents"
            @onApproveShiftCancelationRequest="onReloadEvents"
            @onMarkNoShow="onReloadStaffEvents"
            @onMarkCallOut="onReloadStaffEvents"
            @onUpdateShiftTime="onReloadStaffEvents"
            @onModalClose="onCloseAssignedEventModal"
        ></AssignedEventModal>
        <scheduling-modal
            v-if="showSchedulingModal"
            v-model="showSchedulingModal"
            :request-form-ids-by-day="requestFormIdsByDay"
            :request-details="requestDetails"
            :preselected-values="preselectedSchedulingValues"
            :auth-staffing-details="authStaffingDetails"
            @fillHistoricalShift="onReloadEvents"
            @onEditShift="onEditShift"
            @onEditUser="onEditUser"
            @onCloseModal="onCloseSchedulingModal"
        ></scheduling-modal>
        <unavailable-users-modal
            v-model="unavailableUsersModalValue"
            :users-by-date="unavailableUsersModalData.usersByDate"
            :body-params="unavailableUsersModalData.bodyParams"
            :request-form-id="unavailableUsersModalData.requestFormId"
            @onContinue="onContinueUnavailableUsers"
            @onGoBack="onGoBackUnavailableUsers"
        ></unavailable-users-modal>
        <auto-assign-modal
            v-if="showAutoAssignModal"
            v-model="showAutoAssignModal"
        />
        <auto-assign-results-modal
            v-if="showAutoAssignResultsModal"
            v-model="showAutoAssignResultsModal"
            :data="autoAssignResults"/>
    </div>
</template>

<script>
import SchedulerHeader from './SchedulerHeader'
import DepartmentScheduler from './Department/DepartmentScheduler'
import StaffGroupScheduler from './StaffGroup/StaffGroupScheduler'
import StaffScheduler from './Staff/StaffScheduler'
import AssignedEventModal from '../Modals/AssignedEventModal'
import RequestFormModal from '../Modals/RequestFormModal'
import IntroductoryModal from "../../../pages/StaffingAutomations/IntroductoryModal";
import AddAutomatedShiftModal from '../../../pages/StaffingAutomations/AddAutomatedShift/AddAutomatedShiftModal'
import AddJobTitlesModal from '../../../pages/StaffingAutomations/AddAutomatedShift/AddJobTitlesModal'
import HistoricalRequestFormModal from '../Modals/HistoricalRequestFormModal'
import SchedulingModal from '../Modals/SchedulingModal'
import EditShiftModal from "../../../pages/StaffingAutomations/EditShiftModal";
import UnavailableUsersModal from '../Modals/UnavailableUsersModal'
import AutoAssignModal from "../Modals/AutoAssignModal/AutoAssignModal";
import AutoAssignResultsModal from "../Modals/AutoAssignResultsModal/AutoAssignResultsModal";


export default {
    name: "Scheduler",
    components: {
        StaffGroupScheduler,
        SchedulerHeader,
        DepartmentScheduler,
        StaffScheduler,
        RequestFormModal,
        IntroductoryModal,
        AddAutomatedShiftModal,
        AddJobTitlesModal,
        AssignedEventModal,
        SchedulingModal,
        EditShiftModal,
        UnavailableUsersModal,
        HistoricalRequestFormModal,
        AutoAssignModal,
        AutoAssignResultsModal,
    },
    props: {
        authStaffingDetails: {
            type: Array,
            default: () => ([])
        },
        preselectedOptions: {
            type: Object,
            default: () => ({}),
        }
    },
    data: () => ({
        showSchedulingModal: false,
        requestFormIdsByDay: null,
        requestDetails: {},
        preselectedSchedulingValues: {},
        showRequestFormModal: false,
        showHistoricalRequestFormModal: false,
        preselectedRequestForm: null,
        editRequestForm: false,
        requestFormModalEditMode: false,
        requestFormModalRequestFormProp: {},
        shiftType: 'single',
        unavailableUsersModalValue: false,
        unavailableUsersModalData: {},
        showAssignedEventModal: false,
        assignedModalEvent: null,
        showIntroductoryModal: false,
        showAddAutomatedShiftModal: false,
        staffingAutomationModalValue: false,
        staffingAutomationModalId: null,
        staffingAutomationModalStartDate: null,
        staffingAutomationModalRunDate: null,
        showEditShiftModal: false,
        showAutoAssignModal: false,
        automatedRequestForm: null,
        editAllAutomatedShifts: false,
        showAutoAssignResultsModal: false,
        autoAssignResults: null,
        api: new formHelper(),
    }),
    computed: {
        scheduleViewType: {
            get() {
                return this.$store.getters.schedulerGetScheduleViewType
            },
            set(scheduleViewType) {
                this.$store.dispatch('schedulerSetScheduleViewType', {scheduleViewType})
            }
        },
        shouldReloadDepartmentEvents: {
            get() {
                return this.$store.getters.schedulerGetShouldReloadDepartmentEvents
            },
            set(shouldReloadEvents) {
                this.$store.dispatch('schedulerSetShouldReloadDepartmentEvents', {shouldReloadEvents})
            }
        },
        shouldReloadStaffGroupEvents: {
            get() {
                return this.$store.getters.schedulerGetShouldReloadStaffGroupEvents
            },
            set(shouldReloadEvents) {
                this.$store.dispatch('schedulerSetShouldReloadStaffGroupEvents', {shouldReloadEvents})
            }
        },
        shouldReloadStaffEvents: {
            get() {
                return this.$store.getters.schedulerGetShouldReloadStaffEvents
            },
            set(shouldReloadEvents) {
                this.$store.dispatch('schedulerSetShouldReloadStaffEvents', {shouldReloadEvents})
            }
        },
        changesMadeSchedulingModal: {
            get() {
                return this.$store.getters.getChangesMade
            },
            set(changesMade) {
                this.$store.dispatch('setChangesMade', {changesMade})
            }
        },
        globalLocation: function () {
            return this.$root.globalLocation
        },
        globalTimezone() {
            return this.$root.globalTimezone
        },
    },
    methods: {
        onNewRequestFormClick(shiftType) {
            this.editRequestForm = false
            this.preselectedRequestForm = null
            this.shiftType = shiftType
            if (shiftType === 'historical') {
                this.showHistoricalRequestFormModal = true
            } else if (shiftType === 'automated') {
                if (this.$appData && !this.$appData.staffing_automation_introductory_modal_dismissed) {
                    this.showIntroductoryModal = true
                } else {
                    this.showAddAutomatedShiftModal = true
                }
            } else {
                this.showRequestFormModal = true
            }
        },
        onRequestFormModalClose() {
            this.requestFormModalEditMode = false
            this.requestFormModalRequestFormProp = {}
        },
        onHistoricalRequestFormModalClose() {
            this.requestFormModalEditMode = false
            // this.requestFormModalRequestFormProp = {}
        },
        onCreateHistoricalRequestForm(data) {
            this.showHistoricalRequestFormModal = false
            this.onReloadEvents()
            this.openSchedulingModal(data)
        },
        onCreateRequestForm(data) {
            this.showRequestFormModal = false
            this.onReloadEvents()
            this.openSchedulingModal(data)
        },
        onReloadStaffEvents() {
            if (this.scheduleViewType === 'department') {
                this.shouldReloadStaffEvents = true
                this.shouldReloadStaffGroupEvents = true
            } else if (this.scheduleViewType === 'staff_group') {
                this.shouldReloadDepartmentEvents = true
                this.shouldReloadStaffEvents = true
            } else {
                this.emitFetchStaffEvents()
            }
        },
        onReloadEvents() {
            if (this.scheduleViewType === 'department') {
                this.emitFetchDepartmentEvents()
                this.shouldReloadStaffEvents = true
                this.shouldReloadStaffGroupEvents = true
            } else if (this.scheduleViewType === 'staff_group') {
                this.emitFetchStaffGroupEvents()
                this.shouldReloadDepartmentEvents = true
                this.shouldReloadStaffEvents = true
            } else if (this.scheduleViewType === 'staff') {
                this.emitFetchStaffEvents()
                this.shouldReloadDepartmentEvents = true
                this.shouldReloadStaffEvents = true
            }
        },
        openUnavailableUsersModal(usersByDate, bodyParams, requestFormId) {
            this.unavailableUsersModalValue = true
            this.unavailableUsersModalData = {usersByDate, bodyParams, requestFormId}
        },
        onContinueUnavailableUsers(data) {
            this.unavailableUsersModalValue = false
            this.unavailableUsersModalData = {}
            this.onCreateRequestForm(data)
        },
        onGoBackUnavailableUsers() {
            this.unavailableUsersModalValue = false
            this.unavailableUsersModalData = {}
            this.showRequestFormModal = true
        },
        openSchedulingModal(data, preselectedValues) {
            if (data) {
                const {request_form_ids_by_day, request_details} = data
                if (Array.isArray(request_form_ids_by_day)) {
                    this.requestFormIdsByDay = Array.from(request_form_ids_by_day)
                }
                if (request_details) {
                    this.requestDetails = request_details
                }
                if (preselectedValues) {
                    this.preselectedSchedulingValues = {...preselectedValues}
                }
                this.showSchedulingModal = true
            }
        },
        emitFetchEvents() {
            this.emitFetchDepartmentEvents()
            this.emitFetchStaffGroupEvents()
            this.emitFetchStaffEvents()
        },
        emitFetchDepartmentEvents() {
            window.EventBus.$emit('departmentScheduler/fetchEvents')
        },
        emitFetchStaffGroupEvents() {
            window.EventBus.$emit('staffGroupScheduler/fetchEvents')
        },
        emitFetchStaffEvents() {
            window.EventBus.$emit('staffScheduler/fetchEvents')
        },
        emitOnCloseDepartmentEvent() {
            window.EventBus.$emit('departmentScheduler/onCloseEvent')
        },
        emitOnCloseStaffGroupEvent() {
            window.EventBus.$emit('staffGroupScheduler/onCloseEvent')
        },
        emitOnCloseStaffEvent() {
            window.EventBus.$emit('staffScheduler/onCloseEvent')
        },
        onEditShift(requestForm, shiftType, isFromStaffingAutomation) {
            if (isFromStaffingAutomation) {
                if (shiftType === 'single') {
                    this.showEditNormalShift(requestForm, shiftType)
                } else {
                    this.showEditAutomatedShifts(requestForm, shiftType)
                }
            } else {
                this.showEditNormalShift(requestForm, shiftType)
            }
        },
        showEditAutomatedShifts(requestForm, shiftType) {
            this.automatedRequestForm = {...requestForm}
            this.editAllAutomatedShifts = shiftType !== 'group'
            this.showEditShiftModal = true
        },
        showEditNormalShift(requestForm, shiftType) {
            this.preselectedRequestForm = {...requestForm}
            this.editRequestForm = true
            this.shiftType = shiftType
            this.showRequestFormModal = true
        },
        onEditUser(shiftId) {
            this.showAssignedEventModal = true
            this.assignedModalEvent = {_id: shiftId}
        },
        reloadSchedulingModalChanges() {
            if (this.changesMadeSchedulingModal) {
                this.onReloadEvents()
                this.changesMadeSchedulingModal = false
            }
        },
        fetchSchedulingModalData(event, onSuccess) {
            this.api.get(`/request-forms/group-dates/${event.request_form_id}`)
                .then(data => {
                    if (data && data.data) {
                        this.openSchedulingModal(data.data, {
                            requestFormId: event.request_form_id,
                            shiftRequestId: event.shift_request_id,
                            jobTitleId: event.job_title_id,
                        })
                    }
                }).catch(console.log).finally(() => {
                onSuccess()
            })
        },
        onCloseAssignedEventModal() {
            this.showAssignedEventModal = false
            this.assignedModalEvent = null
        },
        onCloseSchedulingModal() {
            this.reloadSchedulingModalChanges()
        },
        onAddAutomatedShiftNext(staffingAutomationId, startDate, runDate) {
            this.staffingAutomationModalValue = true
            this.staffingAutomationModalId = staffingAutomationId
            this.staffingAutomationModalStartDate = startDate
            this.staffingAutomationModalRunDate = runDate
        },
        onContinueFromIntroductoryModal(dontShowAgain) {
            if (dontShowAgain) {
                this.$appData.staffing_automation_introductory_modal_dismissed = true
            }
            this.showAddAutomatedShiftModal = true
        },
        exportToPDF(data) {
            const scheduleViewType = data.scheduleViewType;

            let dateRange = null
            if (scheduleViewType === 'department') {
                dateRange = this.$refs.DepartmentScheduler.getDateRange();
            } else if (scheduleViewType === 'staff_group') {
                dateRange = this.$refs.StaffGroupScheduler.getDateRange();
            } else if (scheduleViewType === 'staff') {
                dateRange = this.$refs.StaffScheduler.getDateRange();
            }

            this.$emit('exportToPDF', data, dateRange)

        },
        onAddAutoAssignShifts() {
            this.showAutoAssignModal = true
        },
        onAutoAssignResultsSuccess(data) {
            this.showAutoAssignModal = false
            this.showAutoAssignResultsModal = true
            this.autoAssignResults = data
        }
    },
    watch: {
        scheduleViewType(scheduleViewType) {
            if (scheduleViewType === 'department') {
                if (this.shouldReloadDepartmentEvents) {
                    this.emitFetchDepartmentEvents()
                    this.shouldReloadDepartmentEvents = false
                }
            } else if (scheduleViewType === 'staff_group') {
                if (this.shouldReloadStaffGroupEvents) {
                    this.emitFetchStaffGroupEvents()
                    this.shouldReloadStaffGroupEvents = false
                }
            } else if (scheduleViewType === 'staff') {
                if (this.shouldReloadStaffEvents) {
                    this.emitFetchStaffEvents()
                    this.shouldReloadStaffEvents = false
                }
            }
        }
    },
    created() {
        window.EventBus.$on('staffScheduler/onClickEvent', event => {
            if (event.type === 'shift') {
                this.showAssignedEventModal = true
                this.assignedModalEvent = {...event}
            } else if (event.type === 'shiftOffer') {
                this.fetchSchedulingModalData(event, () => {
                    this.emitOnCloseStaffEvent()
                })
            }
        })
        window.EventBus.$on('departmentScheduler/onClickEvent', event => {
            this.fetchSchedulingModalData(event, () => {
                this.emitOnCloseDepartmentEvent()
            })
        })
        window.EventBus.$on('staffGroupScheduler/onClickEvent', event => {
            this.fetchSchedulingModalData(event, () => {
                this.emitOnCloseStaffGroupEvent()
            })
        })
        if (!this.preselectedOptions.preselected_event_not_found) {
            if (this.preselectedOptions.scheduler_view === 'staff') {
                this.scheduleViewType = 'staff'
            } else if (this.preselectedOptions.scheduler_view === 'staff_group') {
                this.scheduleViewType = 'staff_group'
            }
        }
    },
    mounted() {
        if (this.preselectedOptions.auto_schedule_draft) {
            this.onAutoAssignResultsSuccess(this.preselectedOptions.auto_schedule_draft)
        } else {
            if (!this.preselectedOptions.preselected_event_not_found &&
                this.preselectedOptions.scheduler_view === 'staff' && this.preselectedOptions.event_id) {
                this.showAssignedEventModal = true
                this.assignedModalEvent = {_id: this.preselectedOptions.event_id}
            }
            if (!this.preselectedOptions.preselected_event_not_found &&
                this.preselectedOptions.scheduler_view === 'department' &&
                this.preselectedOptions.event_id &&
                this.preselectedOptions.shift_request_id) {
                this.fetchSchedulingModalData({
                    request_form_id: this.preselectedOptions.event_id,
                    shift_request_id: this.preselectedOptions.shift_request_id
                }, () => {
                })
            }
            if (!this.preselectedOptions.preselected_event_not_found &&
                this.preselectedOptions.scheduler_view === 'staff_group' &&
                this.preselectedOptions.event_id &&
                this.preselectedOptions.shift_request_id) {
                this.fetchSchedulingModalData({
                    request_form_id: this.preselectedOptions.event_id,
                    shift_request_id: this.preselectedOptions.shift_request_id
                }, () => {
                })
            }
        }
    }
}
</script>

<style scoped>
.scheduler-outer-container {
    display: block;
    overflow-x: hidden;
    overflow-y: hidden;
    width: 100%;
    height: 100%;
}

.scheduler-inner-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 10px;
    width: 100%;
}
</style>
