<template>
    <v-list :two-line="twoLines" :three-line="threeLines">
        <div v-if="notificationsLoading" class="d-flex align-center justify-center my-5">
            <v-progress-circular
                class="mx-auto"
                :size="50"
                color="primary"
                indeterminate
            ></v-progress-circular>
        </div>
        <v-list-item-group
            v-else-if="parsedNotifications.length > 0"
            active-class="primary--text"
        >
            <template v-for="(notification, index) in parsedNotifications">
                <v-list-item
                    name="notification-item"
                    :key="notification._id"
                    @click="onNotificationClick(notification._id, notification.url, $event)"
                    :class="{ 'read-notification': notification.read_at }"
                >
                    <template>
                        <v-list-item-icon>
                            <v-progress-circular
                                v-if="notification.loading"
                                class="mt-1"
                                :size="30"
                                color="primary"
                                indeterminate
                            ></v-progress-circular>
                            <template v-else-if="notification.data.type === notificationTypes.ASSIGN_SHIFT || notification.data.type === notificationTypes.SHIFT_UPDATED || notification.data.type === notificationTypes.ACCEPT_SHIFT || notification.data.type === notificationTypes.SHIFT_FILLED">
                                <GreyCheckIcon v-if="notification.read_at" class="mt-1"></GreyCheckIcon>
                                <GreenCheckIcon v-else class="mt-1"></GreenCheckIcon>
                            </template>
                            <template v-else-if="notification.data.type === notificationTypes.OFFER_SHIFT || notification.data.type === notificationTypes.SHIFT_OFFER_UPDATED">
                                <GreyPendingIcon v-if="notification.read_at" class="mt-1"></GreyPendingIcon>
                                <YellowPendingIcon v-else class="mt-1"></YellowPendingIcon>
                            </template>
                            <template v-else-if="notification.data.type === notificationTypes.CANCEL_SHIFT_DECLINED">
                                <GreyPendingIcon v-if="notification.read_at" class="mt-1"></GreyPendingIcon>
                                <RedPendingIcon v-else class="mt-1"></RedPendingIcon>
                            </template>
                            <template v-else-if="
                                notification.data.type === notificationTypes.DECLINE_SHIFT ||
                                notification.data.type === notificationTypes.CANCEL_SHIFT_ACCEPTED ||
                                notification.data.type === notificationTypes.USER_UNASSIGNED ||
                                notification.data.type === notificationTypes.CALL_OUT ||
                                notification.data.type === notificationTypes.NO_SHOW
                                "
                            >
                                <GrayCloseIcon v-if="notification.read_at" class="mt-1"></GrayCloseIcon>
                                <RedCloseIcon v-else class="mt-1"></RedCloseIcon>
                            </template>
                            <template v-else-if="notification.data.type === notificationTypes.CANCEL_SHIFT_REQUESTED">
                                <GrayCloseIcon v-if="notification.read_at" class="mt-1"></GrayCloseIcon>
                                <YellowCloseIcon v-else class="mt-1"></YellowCloseIcon>
                            </template>
                            <template v-else-if="notification.data.type === notificationTypes.CANCEL_SHIFT_APPROVED">
                                <AlertIcon color="rgb(206,206,206)" v-if="notification.read_at" class="mt-1"/>
                                <AlertIcon color="rgb(242, 182, 66)" v-else class="mt-1"/>
                            </template>
                            <template v-else-if="notification.data.type === notificationTypes.AVAILABILITY_REMINDER">
                                <AlertIcon color="rgb(206,206,206)" v-if="notification.read_at" class="mt-1"/>
                                <AlertIcon color="rgb(242, 182, 66)" v-else class="mt-1"/>
                            </template>
                            <template v-else-if="notification.data.type === notificationTypes.SHARED_REPORT">
                                <GreyFileTableIcon v-if="notification.read_at" class="mt-1"></GreyFileTableIcon>
                                <BlueFileTableIcon v-else class="mt-1"></BlueFileTableIcon>
                            </template>
                          <template v-else-if="notification.data.type === notificationTypes.AUTO_ASSIGN_RESULTS">
                              <GreyCalendarIcon v-if="notification.read_at" class="mt-1"></GreyCalendarIcon>
                              <BlueCalendarIcon v-else class="mt-1"></BlueCalendarIcon>
                          </template>
                            <template v-else-if="notification.data.type === notificationTypes.AUTO_SCHEDULE_DRAFT_PUBLISHED">
                                <GreyCalendarIcon v-if="notification.read_at" class="mt-1"></GreyCalendarIcon>
                                <BlueCalendarIcon v-else class="mt-1"></BlueCalendarIcon>
                            </template>
                            <template v-else-if="notification.data.type === notificationTypes.AUTO_SCHEDULE_DRAFT_GENERATE_FAILED">
                                <GreyCalendarIcon v-if="notification.read_at" class="mt-1"></GreyCalendarIcon>
                                <BlueCalendarIcon v-else class="mt-1"></BlueCalendarIcon>
                            </template>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title v-text="notification.data.title" :class="notification.read_at ? 'text--caption' : 'text--label'"></v-list-item-title>
                            <v-list-item-subtitle v-text="notification.formatted_body" :style="subtitleStyles" class="text--paragraph"></v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-list-item-action-text v-text="notification.ago" class="text--caption"></v-list-item-action-text>
                            <v-btn v-if="isRemoveList" icon style="color: #0D0A32;" @click.prevent.stop="onNotificationDeleteClick(notification._id)">
                                <v-icon>delete</v-icon>
                            </v-btn>
                        </v-list-item-action>
                    </template>
                </v-list-item>
                <v-divider
                    v-if="index + 1 < parsedNotifications.length"
                    :key="index"
                ></v-divider>
            </template>
        </v-list-item-group>
        <v-list-item v-else>
            <template>
                <v-list-item-content>
                    <v-list-item-title class="text-center grey--text">No new notifications</v-list-item-title>
                </v-list-item-content>
            </template>
        </v-list-item>
        <v-dialog
            v-model="dialog"
            max-width="450"
            @click:outside="onModalLeave"
        >
            <v-card>
                <v-toolbar dark color="error" elevation="0">
                    <v-toolbar-title class="white--text">
                        <div class="d-flex">
                            <v-icon color="white" class="mr-3">mdi-alert</v-icon>
                            <div class="mt-1 no-word-break">
                                Delete Notification
                            </div>
                        </div>
                    </v-toolbar-title>
                </v-toolbar>

                <v-card-text class="mt-6 black--text">
                    <p>Are you sure you want to delete this notification?</p>
                </v-card-text>

                <v-card-actions>
                    <v-row no-gutters>
                        <v-col class="text-right">
                            <v-btn
                                class="mr-2"
                                text
                                color="primary_text"
                                @click="onModalLeave"
                            >
                                No
                            </v-btn>
                            <v-btn
                                class="ml-2"
                                color="error"
                                outlined
                                @click="onNotificationDelete"
                                :loading="isRemoving"
                            >
                                Yes
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-list>
</template>

<script>
    import { NOTIFICATION_TYPES, getNotificationBodyFromEmbedData } from '../../lib/helpers/getNotificationDetails'
    import GreenCheckIcon from "../../components/common/CustomIcon/GreenCheckIcon";
    import GreyCheckIcon from "../../components/common/CustomIcon/GreyCheckIcon";
    import YellowPendingIcon from "../../components/common/CustomIcon/YellowPendingIcon";
    import RedPendingIcon from "../../components/common/CustomIcon/RedPendingIcon";
    import GreyPendingIcon from "../../components/common/CustomIcon/GreyPendingIcon";
    import RedCloseIcon from "../../components/common/CustomIcon/RedCloseIcon";
    import GrayCloseIcon from "../../components/common/CustomIcon/GrayCloseIcon";
    import YellowCloseIcon from "../../components/common/CustomIcon/YellowCloseIcon";
    import AlertIcon from "../../components/common/CustomIcon/AlertIcon";
    import BlueFileTableIcon from "../../components/common/CustomIcon/BlueFileTableIcon";
    import GreyFileTableIcon from "../../components/common/CustomIcon/GreyFileTableIcon";
    import moment from "moment";
    import GreyCalendarIcon from "../../components/common/CustomIcon/GreyCalendarIcon";
    import BlueCalendarIcon from "../../components/common/CustomIcon/BlueCalendarIcon";

    export default {
        name: "NotificationsList",
        components: {
            BlueFileTableIcon,
            GreyFileTableIcon,
            AlertIcon,
            YellowCloseIcon,
            RedCloseIcon,
            GrayCloseIcon, RedPendingIcon, GreenCheckIcon, GreyCheckIcon, GreyPendingIcon, YellowPendingIcon,
            GreyCalendarIcon,
            BlueCalendarIcon
        },
        props: {
            notifications: {
                type: Array,
                default: function() {
                    return []
                }
            },
            numLines: {
                type: Number,
                default: 0
            },
            isRemoveList: {
                type: Boolean,
                default: false
            },
            notificationsLoading: {
                type: Boolean,
                default: false,
            }
        },
        data: function() {
            return {
                dialog: false,
                notificationId: null,
                isRemoving: false,
                notificationsApi: new formHelper(),
                notificationTypes: NOTIFICATION_TYPES
            }
        },
        computed: {
            parsedNotifications: function() {
                return this.notifications.map(notification => ({
                    ...notification,
                    formatted_body: getNotificationBodyFromEmbedData(notification, this.globalTimezone),
                    ago: this.getAgo(notification.created_at)
                }))
            },
            twoLines: function() {
                return this.numLines === 2
            },
            threeLines: function() {
                return this.numLines === 3
            },
            subtitleStyles () {
                if (this.numLines > 0) {
                    return {}
                }
                return {
                    'white-space': 'normal',
                }
            },
            globalTimezone() {
                return this.$root.globalTimezone
            }
        },
        methods: {
            onNotificationClick(notificationId, notificationUrl, event) {
                if(this.isRemoveList) {
                  this.$emit('onNotificationClick', notificationId)
                } else {
                  this.$emit('onNotificationClick', notificationId, notificationUrl, event)
                }
            },
            onNotificationDeleteClick(notificationId) {
                this.dialog = true
                this.notificationId = notificationId
            },
            onNotificationDelete() {
                this.isRemoving = true
                this.notificationsApi.post('/notifications/delete/' + this.notificationId)
                    .then((response) => {
                        if (response.data && response.data.success) {
                            this.$emit('onNotificationDelete', this.notificationId)
                            this.$snackNotify('success', 'Notification deleted.')
                            this.resetModalState()
                        } else {
                            this.$snackNotify('warning', 'Unable to delete this notification. Please try again.')
                            this.isRemoving = false
                        }
                    })
                    .catch((error) => {
                        this.$snackNotify('error', 'Unable to delete this notification. Please try again.')
                        this.isRemoving = false
                    })
            },
            onModalLeave: function() {
                this.resetModalState()
            },
            resetModalState: function() {
                this.isRemoving = false
                this.dialog = false
                this.notificationId = null
            },
            getAgo(date) {
                let start = moment(date).tz(this.globalTimezone)

                if (start.isSame(new Date(), "day")){
                    return start.format('hh:mm A')
                } if (start.isSame(new Date(), "year")) {
                    return start.format('MMM D hh:mm A')
                } else {
                    return start.format('MMM D, YYYY hh:mm A')
                }
            }
        }
    }
</script>

<style scoped>
    .no-word-break {
        word-break: unset;
    }
    .read-notification {
        background-color: #F5F5F5;
    }
</style>
